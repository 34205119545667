import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col, Button } from 'react-bootstrap';
import HeaderCarousel from '../components/carousel';
import SEO from '../components/seo';
import ReactMarkdown from 'react-markdown/with-html'
import './index/style.scss';

export default ({
	location,
}) => {
	// const source = strapiLandingPage; // legacy, data came from Strapi setup.
	const sectionOneContent = "The SKARP Razor cuts hair by the laser light targeting a specific chromophore in the hair. Chromophores are particles that absorb certain wavelengths (colors) of light. This chromophore is equally present in everyone's hair regardless of age, race or gender.\n\nSKARP discovered that when you hit this chromophore with a certain wavelength of light, it cuts the hair. We worked to refine The SKARP Razor so when used, it will leave your skin feeling smoother than any shave you've ever had.\n\n\"Shaving has been using the same basic technology for thirty thousand years. To make any real progress, a radical new approach was of the essence.\"";
	const sectionOneImage = "/videos/learn-more.mp4";
	const sectionOneImageText = "\"To make any real progress, we needed to take a radical new approach.\"\n\n– Dr. Morgan Gustavsson, CEO Skarp Technologies";
	const sectionTwoContent = "SKARP set out with a goal to fundamentally improve shaving. In order to do that, we have to elevate the entire shaving experience. Would the technology in The SKARP Razor provide an experience that completely transcends traditional methods? We believe so.";
	const sectionTwoImage = "/images/about-skarp-technologies.jpg";
	return (
		<>
			<SEO canonical={location.href} />
			<HeaderCarousel />
			<section className="bg-smoke">
				<Container as="section">
					<Row as="section">
						<Col as="article" lg={6}>
							<ReactMarkdown source={sectionOneContent} />
						</Col>
						<Col as="article" lg={6}>
							<video autoPlay playsInline loop muted>
								<source src={sectionOneImage} type="video/mp4" />
							</video>
							<ReactMarkdown className="mt-1" source={sectionOneImageText} escapeHtml={false} />
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Row as="section" className="m-0">
					<Col as="section" lg={5} className="p-0">
						<img src={sectionTwoImage} alt="Woman shaving with SKARP razor" className="nbr" />
					</Col>
					<Col as="section" className="section2-content-padding" lg={7}>
						<Container>
							<ReactMarkdown source={sectionTwoContent} />
							<Link to="/about/">
								<Button>
									Learn more
								</Button>
							</Link>
						</Container>
					</Col>
				</Row>
			</section>
		</>
	);
}