import React from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';
import "./style.scss"

export default () => {
	const items = [
		{
			imageSource: "/images/the-worlds-first-laser-razor-a-razor-fit-for-the-21st-century.jpg",
			title: "The world's first Laser Razor. A Razor fit for the 21st century",
			content: "Shaving is an essential part of most people's daily grooming regime, but It's harmful, painful, expensive and wasteful. Bumps, rashes and cuts are a necessary evil whether you want the perfectly chiseled beard or smooth legs. We have something better!",
		},
		{
			imageSource: "/images/how-is-skarp-shaving-different.jpg",
			title: "How is SKARP shaving different?",
			content: "At first glance, The SKARP Razor looks like a traditional razor. But that's where the similarity ends. It is powered by a small laser which cuts through hair for an incredibly close shave without irritating or damaging the skin.",
		},
		{
			imageSource: "/images/the-skarp-razor-is-for-everyone.jpg",
			title: "The SKARP Razor is for everyone",
			content: "The SKARP Razor is for all, regardless of gender, race or age. Research tells us many women become so frustrated with legacy razors they turn to other methods such as depilatory creams and waxing. Both of those come with their own side effects.",
		},
	];
	const slides = items.map((object, i) => {
		const { title, content, imageSource } = object;

		return (
			<Carousel.Item as="section" key={`carousel-item-${i}`}>
				<Row as="section">
					<Col as="section" lg={5}>
						<img src={imageSource} alt={title} className="cover-image" />
					</Col>
					<Col as="article" lg={7} className="carousel-item-text">
						<h3>{title}</h3>
						<p>{content}</p>
					</Col>
				</Row>
			</Carousel.Item>
		)
	});
	return <Carousel as="section" indicators={false}>{slides}</Carousel>
};